import React, { useState } from 'react'

import {
  AlertIcon,
  Badge,
  BulletFillIcon,
  BulletIcon,
  Button,
  CheckIcon,
  Feed,
  FeedEventCard,
  FeedEventCardHeader,
  FeedItem,
  FeedSection,
  FeedSubItem,
  Heading,
  Inline,
  Link,
  Paragraph,
  PhoneIcon,
  QuestionIcon,
  Spacer,
  StatementIcon,
  Text,
  Time,
  XIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedLongExample = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Feed>
      <FeedSection
        heading={
          <Heading
            level={3}
            size={4}
            noMargin
          >
            Huomioitavaa
          </Heading>
        }
      >
        <FeedItem
          content={
            <FeedEventCard
              headerText={
                <Link as={LinkMock}>Työttömyysetuuden selvityspyyntö</Link>
              }
              headerSubText="Työ- ja virkasuhteesi on päättynyt omasta pyynnöstäsi."
            >
              <Inline
                gap="md"
                wrap="wrap"
              >
                <Button>Vastaa selvityspyyntöön</Button>
                <Button variant="outline">Siirrä palautuspäivää</Button>
              </Inline>
            </FeedEventCard>
          }
          header={
            <FeedEventCardHeader
              accessory={
                <Badge
                  color={'white'}
                  iconLeft={<BulletFillIcon color={'warning'} />}
                >
                  Odottaa toimiasi
                </Badge>
              }
            >
              Saapunut{' '}
              <Time
                date={new Date('2019-11-24')}
                withYear
              />
            </FeedEventCardHeader>
          }
          icon={<AlertIcon />}
          status={'active'}
        />
      </FeedSection>
      <FeedSection
        heading={
          <Heading
            level={3}
            size={4}
            noMargin
          >
            Menneet ja huomioidut
          </Heading>
        }
      >
        <FeedItem
          content={
            <FeedEventCard
              headerText={
                <Link as={LinkMock}>Työttömyysetuuden selvityspyyntö</Link>
              }
              headerSubText="Työ- ja virkasuhteesi on päättynyt omasta pyynnöstäsi."
              headerSubLink={
                <Link as={LinkMock}>Katso lähettämäsi tiedot</Link>
              }
              icon={<QuestionIcon />}
            >
              <Paragraph noMargin>
                <strong>Selvitys on käsittelyssä.</strong>
                <br />
                Saat työvoimapoliittisen lausunnon asiasta normaalin
                käsittelyajan puitteissa. Voit tarkistaa käsittelyajan asiaasi
                hoitavan TE-toimiston sivuilta. Sinun asiaasi hoitaa Uudenmaan
                TE-toimisto.
              </Paragraph>
            </FeedEventCard>
          }
          header={
            <FeedEventCardHeader
              accessory={
                <Badge
                  color={'white'}
                  iconLeft={<BulletIcon color={'success'} />}
                >
                  Käsittelyssä
                </Badge>
              }
            >
              <Time
                date={new Date('2019-11-24')}
                withYear
              />
            </FeedEventCardHeader>
          }
          icon={<AlertIcon />}
          status={'active'}
        />
        <FeedItem
          icon={<CheckIcon />}
          isOpen={isOpen}
          content={
            <FeedEventCard
              headerText={
                <Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>
              }
              headerSubText={
                <>
                  Työttömyysetuuden maksamiselle ei ole työvoimapoliittista
                  estettä{' '}
                  <Time
                    date={new Date('2020-05-01')}
                    withYear
                  />{' '}
                  alkaen.
                </>
              }
              icon={<StatementIcon />}
            />
          }
          header={
            <FeedEventCardHeader>
              Saapunut{' '}
              <Time
                date={new Date('2019-11-14')}
                withYear
              />
            </FeedEventCardHeader>
          }
          onToggle={() => setIsOpen(!isOpen)}
        >
          <FeedSubItem
            content={
              <FeedEventCard
                headerText={
                  <Link as={LinkMock}>Työttömyysetuuden selvityspyyntö</Link>
                }
                headerSubLink={
                  <Link as={LinkMock}>Katso lähettämäsi tiedot</Link>
                }
                headerSubText="Työ- ja virkasuhteesi on päättynyt omasta pyynnöstäsi."
              />
            }
            header={
              <FeedEventCardHeader
                accessory={
                  <Badge
                    color={'white'}
                    iconLeft={<BulletFillIcon color={'success'} />}
                  >
                    Käsitelty
                  </Badge>
                }
              >
                Saapunut{' '}
                <Time
                  date={new Date('2019-11-05')}
                  withYear
                />
              </FeedEventCardHeader>
            }
          />
          <FeedSubItem
            content={
              <FeedEventCard
                headerText={
                  <Link as={LinkMock}>Työttömyysetuuden selvityspyyntö</Link>
                }
                headerSubLink={
                  <Link as={LinkMock}>Katso lähettämäsi tiedot</Link>
                }
                headerSubText="Työ- ja virkasuhteesi on päättynyt omasta pyynnöstäsi."
              />
            }
            header={
              <FeedEventCardHeader
                accessory={
                  <Badge
                    color={'white'}
                    iconLeft={<BulletFillIcon color={'success'} />}
                  >
                    Käsitelty
                  </Badge>
                }
              >
                Saapunut{' '}
                <Time
                  date={new Date('2019-11-05')}
                  withYear
                />
              </FeedEventCardHeader>
            }
          />
        </FeedItem>
        <FeedItem
          header={
            <FeedEventCardHeader
              accessory={<Link as={LinkMock}>Muutoshistoria</Link>}
            >
              <Time
                date={new Date('2019-11-14')}
                withYear
              />{' '}
              Osaaminen ja työtoiveet -tietoja muokattiin
            </FeedEventCardHeader>
          }
        />
        <FeedItem
          icon={<XIcon />}
          content={
            <FeedEventCard
              headerText={
                <Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>
              }
              headerSubText={
                <>
                  Mitätöity: Työnhakusi on voimassa{' '}
                  <Time
                    date={new Date('2019-10-01')}
                    withYear
                  />{' '}
                  lähtien
                </>
              }
              icon={<StatementIcon />}
            />
          }
          header={
            <FeedEventCardHeader
              accessory={<Badge bordered>Ei voimassa</Badge>}
            >
              Saapunut{' '}
              <Time
                date={new Date('2019-11-14')}
                withYear
              />
            </FeedEventCardHeader>
          }
          status={'disabled'}
        />
      </FeedSection>
      <FeedSection
        heading={
          <Heading
            level={4}
            size={5}
            noMargin
          >
            2018
          </Heading>
        }
      >
        <FeedItem
          icon={<CheckIcon />}
          content={
            <FeedEventCard
              headerText={
                <Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>
              }
              headerSubText={
                <>
                  Työnhakusi on voimassa{' '}
                  <Time
                    date={new Date('2018-10-12')}
                    withYear
                  />{' '}
                  lähtien.
                  <br />
                  Tämä lausunto korvaa edellisen{' '}
                  <Time
                    date={new Date('2018-10-12')}
                    withYear
                  />{' '}
                  annetun lausunnon.
                </>
              }
              icon={<StatementIcon />}
            />
          }
          header={
            <FeedEventCardHeader>
              Saapunut{' '}
              <Time
                date={new Date('2018-11-14')}
                withYear
              />
            </FeedEventCardHeader>
          }
        >
          <FeedSubItem
            content={
              <FeedEventCard
                headerText={
                  <Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>
                }
                headerSubText={
                  <>
                    Mitätöity: Työnhakusi on voimassa{' '}
                    <Time
                      date={new Date('2018-10-01')}
                      withYear
                    />{' '}
                    lähtien.
                  </>
                }
                icon={<StatementIcon />}
              />
            }
            header={
              <FeedEventCardHeader
                accessory={<Badge bordered>Ei voimassa</Badge>}
              >
                Saapunut{' '}
                <Time
                  date={new Date('2019-10-12')}
                  withYear
                />
              </FeedEventCardHeader>
            }
            status={'disabled'}
          />
          <FeedSubItem
            content={
              <FeedEventCard
                headerText={
                  <Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>
                }
                icon={<StatementIcon />}
              />
            }
            header={
              <FeedEventCardHeader>
                Saapunut{' '}
                <Time
                  date={new Date('2019-10-12')}
                  withYear
                />
              </FeedEventCardHeader>
            }
          />
        </FeedItem>
        <FeedItem
          header={
            <FeedEventCardHeader>
              <Inline alignItems={'center'}>
                <PhoneIcon
                  block
                  color={'dark'}
                  size={'lg'}
                />
                <Spacer paddingRight={'xs'} />
                <Text>
                  <Time
                    date={new Date('2019-11-14')}
                    withYear
                  />{' '}
                  Puhelu | <Link as={LinkMock}>Link</Link>
                </Text>
              </Inline>
            </FeedEventCardHeader>
          }
        >
          <FeedSubItem
            header={
              <FeedEventCardHeader>
                <Time
                  date={new Date('2019-11-14')}
                  withYear
                />{' '}
                Puhelu | <Link as={LinkMock}>Link</Link>
              </FeedEventCardHeader>
            }
          />
        </FeedItem>
      </FeedSection>
    </Feed>
  )
}

export { FeedLongExample }
