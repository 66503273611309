import React from 'react'

import {
  Feed,
  FeedEventCardHeader,
  FeedItem,
  FeedSection,
  Time
} from '@te-digi/styleguide'

const FeedItemOnlyHeaderExample = () => (
  <Feed>
    <FeedSection>
      <FeedItem
        header={
          <FeedEventCardHeader>
            Saapunut{' '}
            <Time
              date={new Date('2019-11-14')}
              withYear
            />
          </FeedEventCardHeader>
        }
      />
    </FeedSection>
  </Feed>
)

export { FeedItemOnlyHeaderExample }
