import React from 'react'

import {
  AlertIcon,
  CheckIcon,
  Feed,
  FeedItem,
  FeedSection,
  FeedSubItem,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const FeedSimpleExample = () => (
  <Feed>
    <FeedSection heading={<Code>&lt;Heading&gt;</Code>}>
      <FeedItem
        content={<Code>&lt;FeedItem content&gt;</Code>}
        header={<Code>&lt;FeedItem header&gt;</Code>}
        icon={<AlertIcon />}
        status={'active'}
      />
      <FeedItem
        content={<Code>&lt;FeedItem content&gt;</Code>}
        header={<Code>&lt;FeedItem header&gt;</Code>}
        icon={<CheckIcon />}
      >
        <FeedSubItem
          content={<Code>&lt;FeedSubItem content&gt;</Code>}
          header={<Code>&lt;FeedSubItem header&gt;</Code>}
        />
      </FeedItem>
      <FeedItem header={<Code>&lt;FeedItem header&gt;</Code>} />
      <FeedItem
        content={<Code>&lt;FeedItem content&gt;</Code>}
        header={<Code>&lt;FeedItem header&gt;</Code>}
        icon={<XIcon />}
        status={'disabled'}
      />
    </FeedSection>
  </Feed>
)

export { FeedSimpleExample }
