import React from 'react'

import {
  Feed,
  FeedEventCard,
  FeedEventCardHeader,
  FeedItem,
  FeedSection,
  FeedSubItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { FeedCompactExample } from '../../examples/react/FeedCompactExample'
import { FeedEmptyExample } from '../../examples/react/FeedEmptyExample'
import { FeedItemAccessoryExample } from '../../examples/react/FeedItemAccessoryExample'
import { FeedItemActiveExample } from '../../examples/react/FeedItemActiveExample'
import { FeedItemChildrenExample } from '../../examples/react/FeedItemChildrenExample'
import { FeedItemCompactExample } from '../../examples/react/FeedItemCompactExample'
import { FeedItemDisabledExample } from '../../examples/react/FeedItemDisabledExample'
import { FeedItemExample } from '../../examples/react/FeedItemExample'
import { FeedItemHeaderIconExample } from '../../examples/react/FeedItemHeaderIconExample'
import { FeedItemLastExample } from '../../examples/react/FeedItemLastExample'
import { FeedItemOnlyHeaderExample } from '../../examples/react/FeedItemOnlyHeaderExample'
import { FeedItemToggleExample } from '../../examples/react/FeedItemToggleExample'
import { FeedItemWaitingExample } from '../../examples/react/FeedItemWaitingExample'
import { FeedLongExample } from '../../examples/react/FeedLongExample'
import { FeedSimpleExample } from '../../examples/react/FeedSimpleExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Feed"
    components={[
      { component: Feed, restElement: true },
      { component: FeedSection },
      { component: FeedItem, restElement: true },
      { component: FeedSubItem, restElement: true },
      { component: FeedEventCard, restElement: 'Row' },
      { component: FeedEventCardHeader, restElement: 'Row' }
    ]}
  >
    <Section title={'Perustoiminnallisuus'}>
      <Paragraph>
        Kaaviokuva <Code>Feed</Code>-komponentin ja sen alakomponenttien
        käytöstä ja yksinkertaisimmista vaihtoehdoista.
      </Paragraph>
      <Playground>{FeedSimpleExample()}</Playground>
    </Section>

    <Section title={'Yksirivinen kortti'}>
      <Paragraph>
        Kun <Code>content</Code>-proppi ei ole, määritelty saadaan yksirivinen
        kortti.
      </Paragraph>
      <Playground>{FeedItemOnlyHeaderExample()}</Playground>
    </Section>

    <Section title={'Kortin tila'}>
      <Paragraph>
        <Code>status</Code>-propilla määritellään kortin tila. Tila vaikuttaa
        kortin ulkonäköön ja aikajanan ikoniin.
      </Paragraph>
      <Paragraph>Kortin oletustila kun statusta ei ole määritelty.</Paragraph>
      <Playground>{FeedItemExample()}</Playground>
      <Paragraph>
        <Code>active</Code>: asia joka vaatii käyttäjältä huomiota tai
        toimenpiteitä.
      </Paragraph>
      <Playground>{FeedItemActiveExample()}</Playground>
      <Paragraph>
        <Code>waiting</Code>: A-TMT:llä asiantuntijalle näytettävän kortin tila,
        kun asia odottaa asiakkaan toimia.
      </Paragraph>
      <Playground>{FeedItemWaitingExample()}</Playground>
      <Paragraph>
        <Code>disabled</Code>: asia joka ei ole voimassa
      </Paragraph>
      <Playground>{FeedItemDisabledExample()}</Playground>
    </Section>

    <Section title={'Headerin sisältö'}>
      <Paragraph>
        Headerille on mahdollista määrittää <Code>accessory</Code>, johon
        voidaan antaa esimerkiksi <Code>Badge</Code> komponentti.
      </Paragraph>
      <Playground>{FeedItemAccessoryExample()}</Playground>
      <Paragraph>
        Headerille on mahdollista lisätä erilaista sisältöä esimerkiksi ikoni ja
        linkkejä.
      </Paragraph>
      <Playground>{FeedItemHeaderIconExample()}</Playground>
    </Section>

    <Section title={'Tiivis variantti'}>
      <Paragraph>
        Asettamalla <Code>variant={"'compact'"}</Code> kortti näytetään
        pystysuunnassa tiiviimpänä versiona.
      </Paragraph>
      <Playground>{FeedItemCompactExample()}</Playground>
    </Section>

    <Section title={'Tyhjä alue'}>
      <Paragraph>
        <Code>FeedSection</Code> komponentille on mahdollista määritellä
        sisältöä <Code>emptyContent</Code> propilla, kun{' '}
        <Code>FeedSection</Code> ei sisällä kortteja.
      </Paragraph>
      <Playground>{FeedEmptyExample()}</Playground>
    </Section>

    <Section title={'Viimeinen kortti'}>
      <Paragraph>
        Asettamalla <Code>isLast</Code> propin kortin aikajanaviivaa ei piirretä
        kortin jälkeen.
      </Paragraph>
      <Playground>{FeedItemLastExample()}</Playground>
    </Section>

    <Section title={'Alikortit'}>
      <Paragraph>
        Kortille voi määritellä alikortteja. Alikorteilla voi käyttää{' '}
        <Code>status</Code>- ja
        <Code>variant</Code>-proppeja samaan tapaan kuin ylätasollakin.
        Alikortilla ei voi olla alikortteja.
      </Paragraph>
      <Playground>{FeedItemChildrenExample()}</Playground>
      <Paragraph>
        Alikortit on mahdollista piilottaa togglen alle määrittelemällä{' '}
        <Code>onToggle</Code> funktion kortille. <Code>isOpen</Code> propilla
        kontrolloidaan, milloin alikortit näytetään.
      </Paragraph>
      <Playground>{FeedItemToggleExample()}</Playground>
    </Section>

    <Section title={'Toteutuksen kaltainen esimerkki'}>
      <Playground>{FeedLongExample()}</Playground>
      <Paragraph>Samanlainen pitkä esimerkki tiiviimpänä.</Paragraph>
      <Playground>{FeedCompactExample()}</Playground>
    </Section>
  </Content>
)

export default Page
