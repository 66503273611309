import React, { useState } from 'react'

import {
  AlertIcon,
  Badge,
  BulletFillIcon,
  Feed,
  FeedEventCard,
  FeedEventCardHeader,
  FeedItem,
  FeedSection,
  FeedSubItem,
  HideIcon,
  HourglassIcon,
  Inline,
  Link,
  PhoneIcon,
  Spacer,
  StatementIcon,
  Text,
  Time,
  XIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedCompactExample = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Feed>
      <FeedSection>
        <FeedItem
          content={
            <FeedEventCard
              headerText={
                <Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>
              }
              headerSubText={
                <>
                  Mitätöity: Työnhakusi on voimassa{' '}
                  <Time
                    date={new Date('2019-10-01')}
                    withYear
                  />{' '}
                  lähtien
                </>
              }
              icon={<StatementIcon />}
            />
          }
          header={
            <FeedEventCardHeader
              accessory={
                <Badge
                  color={'white'}
                  iconLeft={<BulletFillIcon color={'warning'} />}
                >
                  Odottaa toimiasi
                </Badge>
              }
            >
              <Inline alignItems={'center'}>
                <HideIcon
                  block
                  color={'dark'}
                  size={'lg'}
                />
                <Spacer paddingRight={'xs'} />
                <Text>
                  Saapunut{' '}
                  <Time
                    date={new Date('2019-11-14')}
                    withYear
                  />
                </Text>
              </Inline>
            </FeedEventCardHeader>
          }
          icon={<AlertIcon />}
          isOpen={isOpen}
          onToggle={() => setIsOpen(!isOpen)}
          status={'active'}
          variant={'compact'}
        >
          <FeedSubItem
            content={
              <FeedEventCard
                headerText={
                  <Link as={LinkMock}>Työttömyysetuuden selvityspyyntö</Link>
                }
                headerSubLink={
                  <Link as={LinkMock}>Katso lähettämäsi tiedot</Link>
                }
                headerSubText="Työ- ja virkasuhteesi on päättynyt omasta pyynnöstäsi."
              />
            }
            header={
              <FeedEventCardHeader
                accessory={
                  <Badge
                    color={'white'}
                    iconLeft={<BulletFillIcon color={'success'} />}
                  >
                    Käsitelty
                  </Badge>
                }
              >
                Saapunut{' '}
                <Time
                  date={new Date('2019-11-05')}
                  withYear
                />
              </FeedEventCardHeader>
            }
            variant={'compact'}
          />
        </FeedItem>
        <FeedItem
          content={
            <FeedEventCard
              headerText={
                <Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>
              }
              headerSubText={
                <>
                  Mitätöity: Työnhakusi on voimassa{' '}
                  <Time
                    date={new Date('2019-10-01')}
                    withYear
                  />{' '}
                  lähtien
                </>
              }
              icon={<StatementIcon />}
            />
          }
          header={
            <FeedEventCardHeader
              accessory={
                <Badge
                  color={'white'}
                  iconLeft={<BulletFillIcon color={'warning'} />}
                >
                  Odottaa toimiasi
                </Badge>
              }
            >
              <Inline alignItems={'center'}>
                <HideIcon
                  block
                  color={'dark'}
                  size={'lg'}
                />
                <Spacer paddingRight={'xs'} />
                <Text>
                  Saapunut{' '}
                  <Time
                    date={new Date('2019-11-14')}
                    withYear
                  />
                </Text>
              </Inline>
            </FeedEventCardHeader>
          }
          icon={<AlertIcon />}
          status={'active'}
          variant={'compact'}
        >
          <FeedSubItem
            content={
              <FeedEventCard
                headerText={
                  <Link as={LinkMock}>Työttömyysetuuden selvityspyyntö</Link>
                }
                headerSubLink={
                  <Link as={LinkMock}>Katso lähettämäsi tiedot</Link>
                }
                headerSubText="Työ- ja virkasuhteesi on päättynyt omasta pyynnöstäsi."
              />
            }
            header={
              <FeedEventCardHeader
                accessory={
                  <Badge
                    color={'white'}
                    iconLeft={<BulletFillIcon color={'success'} />}
                  >
                    Käsitelty
                  </Badge>
                }
              >
                Saapunut{' '}
                <Time
                  date={new Date('2019-11-05')}
                  withYear
                />
              </FeedEventCardHeader>
            }
            variant={'compact'}
          />
        </FeedItem>
        <FeedItem
          content={
            <FeedEventCard
              headerText={
                <Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>
              }
              headerSubText={
                <>
                  Mitätöity: Työnhakusi on voimassa{' '}
                  <Time
                    date={new Date('2019-10-01')}
                    withYear
                  />{' '}
                  lähtien
                </>
              }
              icon={<StatementIcon />}
            />
          }
          header={
            <FeedEventCardHeader
              accessory={<Badge bordered>Ei voimassa</Badge>}
            >
              Saapunut{' '}
              <Time
                date={new Date('2019-11-14')}
                withYear
              />
            </FeedEventCardHeader>
          }
          icon={<XIcon />}
          status={'disabled'}
          variant={'compact'}
        >
          <FeedSubItem
            content={
              <FeedEventCard
                headerText={
                  <Link as={LinkMock}>Työttömyysetuuden selvityspyyntö</Link>
                }
                headerSubLink={
                  <Link as={LinkMock}>Katso lähettämäsi tiedot</Link>
                }
                headerSubText="Työ- ja virkasuhteesi on päättynyt omasta pyynnöstäsi."
              />
            }
            header={
              <FeedEventCardHeader>
                Saapunut{' '}
                <Time
                  date={new Date('2019-11-05')}
                  withYear
                />
              </FeedEventCardHeader>
            }
            variant={'compact'}
          />
          <FeedSubItem
            header={
              <FeedEventCardHeader>
                <Time
                  date={new Date('2019-11-14')}
                  withYear
                />{' '}
                Osaaminen ja työtoiveet -tietoja muokattiin |{' '}
                <Link as={LinkMock}>Muutoshistoria</Link>
              </FeedEventCardHeader>
            }
            variant={'compact'}
          />
        </FeedItem>
        <FeedItem
          content={
            <FeedEventCard
              headerText={
                <Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>
              }
              headerSubText={
                <>
                  Mitätöity: Työnhakusi on voimassa{' '}
                  <Time
                    date={new Date('2019-10-01')}
                    withYear
                  />{' '}
                  lähtien
                </>
              }
              icon={<StatementIcon />}
            />
          }
          header={
            <FeedEventCardHeader>
              Saapunut{' '}
              <Time
                date={new Date('2019-11-14')}
                withYear
              />
            </FeedEventCardHeader>
          }
          icon={<HourglassIcon />}
          status={'waiting'}
          variant={'compact'}
        />
        <FeedItem
          header={
            <FeedEventCardHeader>
              <Inline alignItems={'center'}>
                <PhoneIcon
                  block
                  color={'dark'}
                  size={'lg'}
                />
                <Spacer paddingRight={'xs'} />
                <Text>
                  <Time
                    date={new Date('2019-11-14')}
                    withYear
                  />{' '}
                  Puhelu | <Link as={LinkMock}>Link</Link>
                </Text>
              </Inline>
            </FeedEventCardHeader>
          }
          variant={'compact'}
        >
          <FeedSubItem
            header={
              <FeedEventCardHeader>
                <Time
                  date={new Date('2019-11-14')}
                  withYear
                />{' '}
                Puhelu | <Link as={LinkMock}>Link</Link>
              </FeedEventCardHeader>
            }
            variant={'compact'}
          />
        </FeedItem>
      </FeedSection>
    </Feed>
  )
}

export { FeedCompactExample }
