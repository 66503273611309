import React from 'react'

import {
  CheckIcon,
  Feed,
  FeedEventCard,
  FeedEventCardHeader,
  FeedItem,
  FeedSection,
  HideIcon,
  Inline,
  Link,
  PhoneIcon,
  Spacer,
  StatementIcon,
  Text,
  Time
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedItemHeaderIconExample = () => (
  <Feed>
    <FeedSection>
      <FeedItem
        content={
          <FeedEventCard
            headerText={<Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>}
            headerSubText={
              <>
                Mitätöity: Työnhakusi on voimassa{' '}
                <Time
                  date={new Date('2019-10-01')}
                  withYear
                />{' '}
                lähtien
              </>
            }
            icon={<StatementIcon />}
          />
        }
        header={
          <FeedEventCardHeader>
            <Inline alignItems={'center'}>
              <HideIcon
                block
                color={'dark'}
                size={'lg'}
              />
              <Spacer paddingRight={'xs'} />
              <Text>
                Saapunut{' '}
                <Time
                  date={new Date('2019-11-14')}
                  withYear
                />
              </Text>
            </Inline>
          </FeedEventCardHeader>
        }
        icon={<CheckIcon />}
      />
      <FeedItem
        header={
          <FeedEventCardHeader>
            <Inline alignItems={'center'}>
              <PhoneIcon
                block
                color={'dark'}
                size={'lg'}
              />
              <Spacer paddingRight={'xs'} />
              <Text>
                <Time
                  date={new Date('2019-11-14')}
                  withYear
                />{' '}
                Puhelu | <Link as={LinkMock}>Link</Link>
              </Text>
            </Inline>
          </FeedEventCardHeader>
        }
      />
    </FeedSection>
  </Feed>
)

export { FeedItemHeaderIconExample }
