import React from 'react'

import {
  CheckIcon,
  Feed,
  FeedEventCard,
  FeedEventCardHeader,
  FeedItem,
  FeedSection,
  FeedSubItem,
  Link,
  StatementIcon,
  Time
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedItemChildrenExample = () => (
  <Feed>
    <FeedSection>
      <FeedItem
        content={
          <FeedEventCard
            headerText={<Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>}
            headerSubText={
              <>
                Mitätöity: Työnhakusi on voimassa{' '}
                <Time
                  date={new Date('2019-10-01')}
                  withYear
                />{' '}
                lähtien
              </>
            }
            icon={<StatementIcon />}
          />
        }
        header={
          <FeedEventCardHeader>
            Saapunut{' '}
            <Time
              date={new Date('2019-11-14')}
              withYear
            />
          </FeedEventCardHeader>
        }
        icon={<CheckIcon />}
      >
        <FeedSubItem
          content={
            <FeedEventCard
              headerText={
                <Link as={LinkMock}>Työttömyysetuuden selvityspyyntö</Link>
              }
              headerSubLink={
                <Link as={LinkMock}>Katso lähettämäsi tiedot</Link>
              }
              headerSubText="Työ- ja virkasuhteesi on päättynyt omasta pyynnöstäsi."
            />
          }
          header={
            <FeedEventCardHeader>
              Saapunut{' '}
              <Time
                date={new Date('2019-11-05')}
                withYear
              />
            </FeedEventCardHeader>
          }
        />
        <FeedSubItem
          header={
            <FeedEventCardHeader>
              <Time
                date={new Date('2019-11-14')}
                withYear
              />{' '}
              Puhelu | <Link as={LinkMock}>Link</Link>
            </FeedEventCardHeader>
          }
        />
      </FeedItem>
    </FeedSection>
  </Feed>
)

export { FeedItemChildrenExample }
