import React from 'react'

import {
  CheckIcon,
  Feed,
  FeedSection,
  Heading,
  Inline,
  Paragraph
} from '@te-digi/styleguide'

const FeedEmptyExample = () => (
  <Feed>
    <FeedSection
      emptyContent={
        <Inline
          alignItems="center"
          gap="xs"
        >
          <CheckIcon
            block
            color="success"
            size="lg"
          />
          <Paragraph noMargin>Ei huomioitavia tapahtumia.</Paragraph>
        </Inline>
      }
      heading={
        <Heading
          level={3}
          size={4}
          noMargin
        >
          Huomioitavaa
        </Heading>
      }
    />
  </Feed>
)

export { FeedEmptyExample }
