import React from 'react'

import {
  CheckIcon,
  Feed,
  FeedEventCard,
  FeedEventCardHeader,
  FeedItem,
  FeedSection,
  Link,
  StatementIcon,
  Time
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const FeedItemLastExample = () => (
  <Feed>
    <FeedSection>
      <FeedItem
        content={
          <FeedEventCard
            headerText={<Link as={LinkMock}>Työvoimapoliittinen lausunto</Link>}
            headerSubText={
              <>
                Mitätöity: Työnhakusi on voimassa{' '}
                <Time
                  date={new Date('2019-10-01')}
                  withYear
                />{' '}
                lähtien
              </>
            }
            icon={<StatementIcon />}
          />
        }
        header={
          <FeedEventCardHeader>
            Saapunut{' '}
            <Time
              date={new Date('2019-11-14')}
              withYear
            />
          </FeedEventCardHeader>
        }
        icon={<CheckIcon />}
        isLast
      />
    </FeedSection>
  </Feed>
)

export { FeedItemLastExample }
